import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
// import Box from 'components/box';
// import Title from 'components/title';
// import Gallery from 'components/gallery';
// import IOExample from 'components/io-example';
import HeaderJumbotron from 'components/headerJumbotron';
import BookingInputBox from 'components/bookingInputBox';
import IconSection from 'components/iconSection';
import ZetaSection from 'components/zetaSection';
import GenericTitleTextCTA from 'components/generic/TitleTextCTA';
import RoomsBox from 'components/roomsBox';
import MapsSection from 'components/mapsSection';
import Footer from 'components/footer';
// import { Container, Row, Col } from 'reactstrap';
import SEO from 'components/seo/index';
// import BackgroundImage from 'gatsby-background-image'
// import Img from 'gatsby-image';

import { graphql } from 'gatsby';
// import { sep } from 'path';
const jsonLD = {
  "@context": "http://schema.org",
  "@type": "Hotel",
  "name": "Hotel Grace Galaxy - Bandra West",
  "description": `Lilavati Bandra, hospital. Patient friendly, Clean and affordable hotel rooms in bandra`,
  "address": {
    "@type": "PostalAddress",
    "addressCountry": "IN",
    "addressLocality": "Bandra",
    "addressRegion": "Mumbai",
    "postalCode": "400050",
    "streetAddress": "SV Road, Near Shoppers Stop, Bandra West"
  },
  "image": "https://hotelgracegalaxy.com/premium-hotel-room-bandra-mumbai.jpg",
  "telephone": "+91 9538-740-296",
  "starRating": {
    "@type": "Rating",
    "ratingValue": "4"
  },
  "priceRange": "Rs.2500 - Rs.5000",
  "checkinTime": "12:30:00",
  "checkoutTime": "11:30:00"
}
const basicSEO = {
  title: "Lilavati Bandra, hospital. Patient friendly, Clean and affordable hotel rooms in bandra.",
  description: "Hotel Grace Galaxy is the best hotel in bandra west in it's class, known for it's prime location, clean rooms and friendly staff",
  canonical: "https://hotelgracegalaxy.com/lilavati-bandra-hospital-near-hotel-in-bandra"
}
const Index = ({ data }) => (
  <Layout>
    <SEO basicSEO={basicSEO} jsonLD={jsonLD} />
    <HeaderJumbotron jumboImage={data.lilavatiHospital} headerText="Comfortable stay, near lilavati hospital mumbai" />
    <BookingInputBox />
    <IconSection title="Clean, affordable, spacious rooms with caring staff" />
    <GenericTitleTextCTA
      title="Hotel Room Near Lilavati Hospital"
      text="Hotel Grace Galaxy is a Medical tourism friendly hotel. Have special requirements? call us and we'll arrange a comfortable stay for you!"
      callToActionText="Book Now"
      callToActionUrl="/book" />
    <RoomsBox />
    <ZetaSection />
    <MapsSection />
    <Footer />
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query {
    rgsl: file(
      relativePath: { eq: "images/gallery/rajiv-gandhi-sea-link.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    royalRoom: file(
              relativePath: {
                eq: "images/gallery/royal-hotel-room-bandra-mumbai.jpg"
              }
            ) 
            {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
    lilavatiHospital: file(
      relativePath: {
        eq: "images/gallery/lilavati-hospital.jpg"
      }
    ) 
    {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bwsl: file(
      relativePath: { eq: "images/gallery/bandra-worli-sea-link.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    concept: file(relativePath: { eq: "images/gallery/concept.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    file(relativePath: { eq: "images/gallery/bandra-worli-sea-link.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
